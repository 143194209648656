"use client"

import Style from "./Plans.module.scss";
import OfferButton from "../OfferButton";
import { useContext, useEffect, useState } from "react";
import formatPrice from "lib/Utils/formatPrice";
import DeclineProtection from "../OfferButton/DeclineProtection";
import { OfferContext } from "thirdparty/Extend/context/OfferContext";
import { useExtendCart } from "thirdparty/Extend/hooks/useExtendCart";
import LoadingPlans from "./preloaders/LoadingPlans";
import { useRouter } from "next/navigation";
import useButtonFeedback from "components/forms/Button/useButtonFeedback";
import clsx from "clsx";
import Loader from "components/feedback/Loader";
import Alert from "components/feedback/Alert";
import Button from "components/forms/Button";


function Plans({ 
    sku, 
    context,
    autoAddPlan = false,
    plans,
    leadToken = null
}){
    const [ state, dispatch ] = useContext(OfferContext);
    const extendCart = useExtendCart()
    const router = useRouter()
    const feedback = useButtonFeedback()
    const [ declined, setDeclined ] = useState(false)

    function undo(){
        setDeclined(false)

        dispatch({
            type: "setPlan",
            data: {
                id: null,
                product_sku: sku
            }
        })
    }


    useEffect(() => {
        if( autoAddPlan && state?.selected[sku]?.sku ){
            feedback.setLoading(true)

            extendCart.add({
                planSku: sku,
                name: state?.selected[sku]?.name,
                sku: state?.selected[sku]?.sku,
                price: state?.selected[sku]?.price,
                qty: context?.qty
                
            },(response) => {
                if( !window && response?.data ){ 
                    router.push(`/cart?id=${response?.data?.id}`) 
                }

                const urlParams = window.location.search

                // if we are still on the PDP then push them to cart to add more plans
                if( urlParams.includes("product=") && urlParams.includes("id=") ){
                    router.push(`/cart?id=${response?.data?.id}`)

                }else{
                    router.refresh()
                }
            })
        }

    }, [ 
        state?.selected[sku]?.sku,
        autoAddPlan,
        sku
    ])


    return(
        <aside className={Style.block}>
            <Loader 
                isLoading={feedback.loading} 
                className={Style.loader}
                isDark
            />

            {(Array.isArray(plans?.list) && plans?.list?.length > 0) ? (
                <>
                    {!declined ? (
                        <menu className={clsx(Style.list, feedback.loading && Style.atcPlanLoading)}>
                            {plans?.list.map((plan) => {
                                return (
                                    <OfferButton 
                                        isDisabled={feedback.loading}
                                        key={`${plan.id};xtd;${sku}`} 
                                        {...plan}
                                        {...{ 
                                            isLoading: plans?.isLoading?.buttons,
                                            qty: context?.qty,
                                            sku,
                                            leadToken,
                                            planPrice: formatPrice((plan?.price/100) * context?.qty)
                                        }}
                                    />
                                )
                            })}

                            <DeclineProtection 
                                {...{ sku }} 
                                isDisabled={feedback.loading}
                                onClick={() => setDeclined(true)}
                            />
                        </menu>

                    ):(

                        <Alert color="ice25" className={Style.declined}>
                            <span>I don't want product protection.</span>

                            <Button
                                color="transparent" 
                                noAnimate
                                size="sm"
                                onClick={(e) => undo()}
                                type="button"
                                className={Style.undo}
                            >
                                <u>Undo</u>
                            </Button>
                        </Alert>
                    )}
                </>
            ):(
                <LoadingPlans />
            )}
        </aside>
    )
}



export default Plans;