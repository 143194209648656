// import IconExtend from "thirdparty/Extend/icons/IconExtend";
import Style from "./OfferButton.module.scss";
import { useContext } from "react";
import { IconClose } from "components/icons/IconClose";
import { OfferContext } from "thirdparty/Extend/context/OfferContext";
import clsx from "clsx";


function DeclineProtection({ id = "decline", sku, onClick }){
    const [ state, dispatch ] = useContext(OfferContext);
   
    function decline(){
        onClick()
        
        dispatch({
            type: "setPlan",
            data: {
                id,
                product_sku: sku
            }
        })
    }


    return(
        <li className={Style.item}>
             <label 
                htmlFor={id} 
                className={clsx(Style.block, state?.selected?.[sku]?.id === "decline" && Style.selected)} 
                onClick={decline}
            >
                <input 
                    type="radio" 
                    name={`offer-${sku}-decline`}
                    value="decline" 
                    id={id} 
                    className={Style.control} 
                />

                <span className={Style.period}>
                    <strong>
                        Decline
                        <small>Protection Plan</small>
                    </strong>
                </span> 

                <span className={Style.price}>
                    <IconClose height="30" />
                </span>
            </label>
        </li>
    )
}



export default DeclineProtection;