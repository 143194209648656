/**
 * Get Url for logged in checkout 
 * @param {int} customerId 
 * @param {string} checkoutUrl
 * @returns {string} LoginUrl
 */

import { v4 as uuidv4 } from "uuid";
import { teakAPI } from 'services/AuthenteakApi';


export default function GetLoggedJwtToken(customerId, checkoutUrl){
    const clientId = process.env.NEXT_PUBLIC_bigCommerce_clientId;
    const clientSecret = process.env.NEXT_PUBLIC_bigCommerce_clientSecret;
    const storeHash = process.env.NEXT_PUBLIC_bigCommerce_storeHash;
    const storeUrl = process.env.NEXT_PUBLIC_checkoutUrl;
    const dateCreated = Math.round((new Date()). getTime() / 1000);
    
    const  message = {
        "iss": clientId,
        "iat": dateCreated,
        "jti": uuidv4(),
        "operation": "customer_login",
        "store_hash": storeHash,
        "customer_id": customerId,
        "checkout_url": storeUrl,
        "redirect_to": checkoutUrl
    }

    const payload = {
        message: message,
        clientSecret: clientSecret,
        algorithm: 'HS256'
    }

    return teakAPI.post("/api/teakAPI/generateJwtToken", payload);

}
