"use client"

import Style from "./Plans.module.scss";
import { useRef } from "react";
import OfferModal from "../OfferModal";
import OfferHighlights from "./Highlights";



function ExtendHelpText({ text, cta }){
    const modal = useRef(null)

    return(
        <>
            <span className={Style.helpText}>
                How does it work? &nbsp;
                
                <button 
                    type="button" 
                    className={Style.learnMore}
                    onClick={() => modal.current.showModal()}
                >
                    Learn more
                </button>
            </span>

            <OfferModal ref={modal}>
                <OfferHighlights {...{ text, cta }} />
            </OfferModal>
        </>
    )
}

export default ExtendHelpText;