"use client"

import atcCustomProduct from "components/applications/cart/helpers/atcCustomProduct";
import { removeCartItem } from "components/applications/cart/helpers/Cart.helpers";
import { useCart } from "components/applications/cart/hooks/useCart";
import { useRouter } from "next/navigation";
import { LocalStorage, storageKeys } from "services/LocalStorage.service";


export function useExtendCart() {
    const { itemMutation } = useCart()
    const router = useRouter()
    const errorCallback = () => null


    function reload(){
        setTimeout(() => router.refresh(), 1000)
    }


    /**
     * 
     * @param {id} param0 
     */
    function updateCartPlan({ 
        id, 
        cartId, 
        name, 
        sku, 
        qty, 
        price, 
        callback = () => null 
    }){
        const productBody = {
            custom_items: [{
                sku,
                name,
                quantity: qty,
                list_price: price,
            }]
        }

        itemMutation.mutate({
            type: "updateItem",
            id,
            productBody,
            cartId
        }, {
            onSettled: (data) => callback(data),
            onSuccess: (data) => callback(data)
        })
    }





    /**
     * Remove Extend Plan from cart
     * @param {string} id - cart product id
     * @param {string} cartId - cart id
     * @param {function} callback - function to run after successful removal 
     * @param {boolean} refresh - should we refresh the page after removal
     */

    function removePlanFromCart({ 
        id, 
        cartId, 
        callback = () => null, 
        refresh = true 
    }) {
        itemMutation.mutate({
            productId: id,
            cartId,
            type: "removePlan",
            refresh
        }, {
            onSettled: () => callback(),
            onSuccess: () => callback()
        })
    }





    /**
     * Removes both the product and it's attached plan from the cart
     * @param {string} planCartId
     * @param {string} cartId
     * @param {string} id
     * @param {string} product_id 
     */

    function removeProductAndPlanFromCart({ 
        planCartId, 
        cartId, 
        id, 
        product_id 
    }) {
        removePlanFromCart({
            cartId,
            id: planCartId,
            refresh: false,
            callback: () => {
                removeCartItem({
                    id,
                    cartId,
                    itemMutation,
                    product_id,
                    callback: () => reload()
                })
            }
        })
    }





    /**
     * Add Extend plan to cart
     * @param {string} - product sku 
     * @param {string} - product name
     * @param {number} - product quantity
     * @param {number} - product price
     * @param {string} - cart id (if we need to add it to a specific cart)
     * @param {boolean} - buy now (if we want to redirect to checkout after adding)
     * @param {function} - callback function to run after successful addition 
     */

    async function add(
        { 
            sku, 
            name, 
            qty, 
            price, 
            cartId = null, 
            buyNow = false 
        }, callback = () => null
    ){
        atcCustomProduct({
            productData: {
                sku,
                name,
                quantity: qty,
                list_price: price
            },
            buyNow,
            cartId,
            itemMutation,
            errorCallback,
            confirmationCallback: (data) => callback(data)
        })
    }






    /**
     * Buy Protection Plan Now
     * @param {string} sku,
     * @param {string} name,
     * @param {number} qty,
     * @param {number} price,
     * @param {string} cartId
     * @returns redirect to checkout 
     */

    function buyNow({ 
        sku, 
        name, 
        qty, 
        price, 
        cartId = null 
    }) {
        add({ sku, name, qty, price, cartId, buyNow: true }, (response) => {
            if( response?.data && window ){
                window.location.href = response.data?.redirect_urls?.checkout_url
            }
        })
    }




    /**
     * Check to see if the current cart has a plan with the same sku in the custom items array
     * @param {string} sku 
     * @returns object
     */

    function checkForCurrentPlans(sku) {
        const currentCartPlans = LocalStorage.getStorage(storageKeys.cart)

        // if no cart
        if (!currentCartPlans) return null;

        const matchedPlan = currentCartPlans.line_items.custom_items.filter(item => item.sku.includes(sku))

        if (Array.isArray(matchedPlan) && matchedPlan.length) {
            return {
                id: matchedPlan[0].id,
                cartId: currentCartPlans.id
            }

        } else {
            return null
        }
    }




    /**
     * need to check cart to see if there is already an item for this product.
     * if so we need to remove it and add the new one.
     * @param {string} name 
     * @param {string} sku
     * @param {number} qty
     * @param {number} price
     * @param {string} planSku
     * @param {function} callback
     */

    async function addToCart({
        name,
        sku,
        qty,
        price,
        planSku,
        callback = () => null
    }) {
        
        const havePlanAlready = checkForCurrentPlans(planSku)

        if (havePlanAlready && Object.keys(havePlanAlready).length) {
            updateCartPlan(
                {
                    id: havePlanAlready.id,
                    cartId: havePlanAlready.cartId,
                    name, 
                    sku, 
                    qty, 
                    price
                }, 
                (data) => {
                    callback(data)
                    refresh()
                }
            )

        } else {
            add({ name, sku, qty, price }, callback)
        }
    }




    return {
        add,
        buyNow,
        reload,
        addToCart,
        itemMutation,
        updateCartPlan,
        removePlanFromCart,
        removeProductAndPlanFromCart
    }
}