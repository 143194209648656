// import IconExtend from "thirdparty/Extend/icons/IconExtend";
import Style from "./OfferButton.module.scss";
import { useContext, useRef } from "react";
import Skeleton from "components/feedback/Skeleton";
import { OfferContext } from "thirdparty/Extend/context/OfferContext";
import clsx from "clsx";
import { ProductOptionContext } from "components/applications/pip/contexts/ProductOptionContext";

function OfferButton({ 
    contract = null, 
    price = null, 
    planPrice = null,
    id = "", 
    isLoading = false,
    sku = "",
    qty = 1,
    leadToken = null,
    isDisabled = false
}){
    const [ state, dispatch ] = useContext(OfferContext);
    const [ productOptionState, productDispatch ] = useContext(ProductOptionContext);

    const termLength = useRef(contract?.termLength/12);
    const planSku = useRef(leadToken ? `${id};xtd;LEAD::${leadToken}::MAX::${qty}` : `${id};xtd;${sku}`)

    function add(){
        productDispatch({
            type: "haveProtectionPlan",
            data: planSku.current
        })

        dispatch({
            type: "setPlan",
            data: {
                id,
                name: `Extend Protection Plan - ${termLength.current} years`,
                price: price/100,
                sku: planSku.current,
                qty,
                product_sku: sku
            }
        })
    }

    return(
        <li className={Style.item}>
            <label 
                htmlFor={planSku.current} 
                className={clsx(Style.block, state?.selected?.[sku]?.sku === planSku.current && Style.selected)} 
                onClick={add}
                
            >
                <input 
                    type="radio" 
                    name={`offer-${sku}`} 
                    id={planSku.current}
                    value={planSku.current} 
                    className={Style.control} 
                    disabled={isDisabled}
                />

                <span className={Style.period}>
                    <strong>
                        {termLength.current} Year
                        <small>Protection Plan</small>
                    </strong>
                    
                </span>

                {isLoading ? (
                    <span className={Style.price}>
                        <Skeleton width="15%" height="18px" /> &nbsp;
                        <Skeleton width="40%" height="18px" />
                    </span>
                ):(
                    <span className={Style.price}>
                        {planPrice}
                    </span>
                )}
                
            </label>
        </li>
    )
}

export default OfferButton;