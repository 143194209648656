"use client"

import { useEffect, useState } from "react";
import { getExtendOffer } from "../services/Extend.service";


export function useGetExtendPlans({ context, sku, category, price }){
    const [ plans, setPlans ] = useState(null)
    const [ isLoading, setIsLoading ] = useState({
        buttons: false,
        addPlan: false
    });


    useEffect(() => {
        if( !context?.price ) return;

        setIsLoading({
            ...isLoading,
            buttons: true
        });

        getExtendOffer({ sku, category, price: context?.price ?? price }).then(resData => {
            const list = [].concat(resData?.plans?.adh, resData?.plans?.base);
            const marketing = resData?.marketing?.adh ?? resData.marketing?.base

            setPlans({
                marketing,
                list,
                qty: context?.qty
            })

            setTimeout(() => setIsLoading({ ...isLoading, buttons: false }), 2000);
        });

    }, [
        context?.price,
        context?.qty
    ])


    return{
        ...plans,
        isLoading
    }
}