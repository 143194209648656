import Style from './PlanHighlights.module.scss';
import IconExtendCheck from 'thirdparty/Extend/icons/IconExtendCheck';
import { useMemo } from 'react';
import range from 'lib/Utils/range';
import AuthenteakExtendLogo from '../AuthenteakExtendLogo';


function OfferHighlights({ text, cta = "#" }){
    const points = useMemo(() => {
        const totalPoints = range(1, 4)

        return totalPoints.map((index) => {
            if( index >= 4 ) return null

            return(
                <div className={Style.item} key={`point-${index}`}>
                    <IconExtendCheck height="50" className={Style.itemIcon} />

                    <dt className={Style.term}>
                        {text?.[`benefitTitle${index}`] }
                    </dt>
                    <dd className={Style.description}>
                        {text?.[`benefitBody${index}`] }
                    </dd>
                </div>
            )
        })

    }, [ text ])


    return(
        <article className={Style.block}>
            <header className={Style.header}>
                <AuthenteakExtendLogo />

                <h4 className={Style.heading}>
                    {text?.headline ?? "Get Peace of Mind with Product Protection from Extend"}
                </h4>
            </header>           

            <dl className={Style.list}>
                {points}
            </dl> 

            <footer className={Style.footer}>
                <a href={cta} target="_blank" className={Style.footerLink}>
                    Details & FAQs
                </a>
            </footer>
        </article>
    )
}

export default OfferHighlights;