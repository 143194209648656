import axios from 'axios';

// axios instance with a dynamic base URL
const createApiInstance = (apiURL) => {
  return axios.create({
  baseURL: apiURL
});
};

// Default Authenteak API base URL
const API = createApiInstance(process.env.NEXT_PUBLIC_api_endpoint + '/api');

// proxy API base URL
const teakAPI = createApiInstance(process.env.NEXT_PUBLIC_siteUrl);

export { teakAPI, API };
