import { fabricOptions } from "lib/Constants/fabricOptions";


/** -------------------------------
 * PDP/PIP Utility Helper Methods
 * Call upon us when needed
---------------------------------- */


// creates the trade discount based on the % indicated in the product
export function getTradePrice(totalUpCharges, trade_discount = 0){
    const tradeDiscount = !!trade_discount ? trade_discount : 0;
    const discount = parseInt(tradeDiscount)/100
    const tradePrice = totalUpCharges - (totalUpCharges * discount);

    return tradePrice;
}





// Put No Thanks swatch first in the swatch list
export function noThanksFirst(swatches){
    const noThanks = swatches.find(swatch => swatch?.label && ["thank", "Not"].some(word => swatch?.label.toString().includes(word)))


    if( noThanks ){
       return [
                noThanks,
                ...swatches.filter(swatch => !["No ", "Not"].some(word => swatch?.label.toString().toLowerCase().includes(word.toLowerCase())))
       ];

        
    }else {
        return swatches
    }
}




// Sort optionally by 4 factors
export function sortSwatchesByThenBy(swatches = [], by, then, thenAgain, finallyBy){
    const compare = (a, b) => (a > b) - (a < b);

    return swatches.sort((a, b) => {
        return  compare(a[by], b[by]) || 
                compare(a[then], b[then]) || 
                compare(a[thenAgain], b[thenAgain]) || 
                compare(a[finallyBy], b[finallyBy])
    })
}







/**
 * build options to show and set in options context
 * @param {array} options 
 * @returns options object
 */

export function buildOptions(options){
    const obj = {};

    if( Array.isArray(options) && options.length ){
        options.forEach((option, index) => {
            obj[option?.display_name] = {
                index: index,
                attribute: option?.option_group_id,
                attributeValue: null
            }
        });
    }

    return obj;
}







/**
 * build global set options to show and set in options context
 * @param {array} setOptions 
 * @returns 
 */

function buildSetGlobalOptions(setOptions){
    const obj = {}

    setOptions.forEach((option, index) => {
        obj[option.product_id] = {
            ...obj[option.product_id],
            [option.display_name]: {
                index: index,
                attribute: option.option_group_id,
                attributeValue: null,
                variant_id: option.variant_id,
                product_id: option.product_id
            }
        }
    });


    return obj;
}





/**
 * Check for selected options
 * @param {string} type 
 * @param {array} options 
 * @param {string} product_id 
 * @returns 
 */

function hasUpCharges({ type, options, product_id, state }){
    if( type === "sets" ){
        return options.some(option => state.sets?.[product_id]?.[option?.display_name]?.attributeValue !== null )

    }else if( type === "options" ){
        return options.some(option => state?.options?.[option?.display_name]?.attributeValue !== null )
    }
}




/**
 * Get the JSON Object to send to cart
 * @param {string} type - define the type of object
 * @param {string} qty 
 * @param {string} productId 
 * @param {string} variantId
 * @param {object} options
 * @returns JSON Object
*/

function getCartBody(type, 
    { 
        qty, 
        productId, 
        variantId, 
        options = {}, 
        customerId = 0
    }
){
    
    if( type === "cartBody" ){
        const body = {
            "customer_id": parseInt(customerId),
            "line_items": [
                {
                    "quantity": qty,
                    "product_id": productId,
                    "variant_id": variantId,
                }
            ]
        }

        if( Object.values(options).length ){            
            body.line_items[0]["option_selections"] = Object.values(options).map(item => {
                return {
                    "option_id": item.attribute,
                    "option_value": item.attributeValue,
                    "value_id": item.attributeValue,
                    "name_id": item.attributeValue
                }
            })
        }

        return body;

        
    }else if( type === "itemBody"){
        return  {
            "customer_id": parseInt(customerId),
            "line_item": {
                "quantity": qty,
                "product_id": productId,
                "variant_id": variantId,
            }
        }
    }
    
}






 /**
  * check if we can set the swatch request
  * @param {array} options 
  * @returns boolean
  */

 export function canRequestSwatchChecker(options){
    let canRequest = false

    for (let i = 0; i < options.length; i++) {
        if( !fabricOptions.every(value => value !== options[i].display_name) ){
            canRequest = true;
            break;
        }        
    }

    return canRequest;
}








/**
 * 
 * @param {string} contextState 
 * @returns - context string for product option reducer
 */

function getUpdateReducerName(contextState){
    switch(contextState){
        case "options": return "updateOptions";
        case "sets": return "updateSets";
        case "addons": return "updateAddons"
        case "cart": return "updateCart"
        default: break;
    }
}




/**
 * Calculates product set total + any options added
 * @param {array} sets - set options array from context
 * @param {string} excludeGlobal - the actual set product id we are using for the global option
 * @param {number} qty - # of set of products (products in the set * qty)
 * @returns number
 */

function calculateSetUpCharges({ sets, excludeGlobal, qty = 1 }){
    const totals = [];

    // Add Products
     Object.values(sets.products).forEach(product => {
        totals.push((product.price ?? 0) * product.quantity)
    })

    // add options
    const newSets = { ...sets };

    delete newSets.products;

    Object.keys(newSets).forEach(key => {
        if( key !== excludeGlobal ){
            const upCharges = Object.values(newSets[key]).map(option => option?.price ?? 0)
            const totalUpCharges = upCharges.reduce((prev, curr) => prev + curr);

            totals.push(totalUpCharges * qty)
        }
    })

    return totals.reduce((prev, curr) => prev + curr);
}





/**
 * Calculates product total + any options added
 * @param {number} price - product price
 * @param {number} qty - # of products
 * @param {array} options - options array from context
 * @returns number
 */

function calculateUpCharges({ price, qty, options }){
    const upCharges = options.map(item => item?.price ?? 0)
    const totalUpCharges = upCharges.reduce((prev, curr) => prev + curr);

    return (totalUpCharges + price) * qty
}






/**
 * Validates product options
 * @param {Object} stateOptions - options array from context
 * @returns boolean
 */

function validateOptions(stateOptions){
    if( stateOptions.hasOwnProperty("products") ){ 
        delete  stateOptions.products 
    }

    const nonValid = Object.keys(stateOptions).filter(opt => stateOptions[opt].attributeValue === null);

    return nonValid.map(item => {
        return { display_name: item }
    });
}







/**
 * 
 * @param {object} obj 
 * @returns 
 */

function getRatingPercent(obj) {
    const scoreByPercent = (obj) => {
            const total = Object.values(obj).reduce((a, b) => a + b, 0);

            let per = {}
            Object.keys(obj).map((key) => { 
                per[key] = ((obj[key]/total)*100).toFixed()
            }
        )
        return per;
    }

    return scoreByPercent(obj); 
}



export{
    buildSetGlobalOptions,
    hasUpCharges,
    getCartBody,
    calculateSetUpCharges,
    calculateUpCharges,
    validateOptions,
    getUpdateReducerName,
    getRatingPercent,
}