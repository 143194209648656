import { IconLogoOcean } from 'components/icons/IconLogoOcean';
import Style from '../Plans/Highlights/PlanHighlights.module.scss';
import ExtendLogo from 'thirdparty/Extend/icons/ExtendLogo';
import { IconPlus } from 'components/icons/IconPlus';
import clsx from 'clsx';


function AuthenteakExtendLogo({ className }){
    return( 
        <div className={clsx(Style.logos, className)}>
            <IconLogoOcean className={Style.logo} height="25" />  
            <IconPlus className={Style.plus} width="30" height="30" />
            <ExtendLogo className={Style.logo} height="20" />
        </div>
    )
}

export default AuthenteakExtendLogo;