import { ga4EventData } from 'thirdparty/Google/helpers/event.helpers';
import { setRejoinerCheckout } from 'thirdparty/Rejoiner/Rejoiner.service';
import { trackProductsConverted } from 'services/plp/SearchApi.service';
import { sendGTMEvent } from '@next/third-parties/google'


/**
 *  Analytics helper on start of checkout
 * @param {object} cartData 
 * @param {string} userId 
 * @param {array} prodCat 
 */
export function beginCheckoutAnalytics(cartData, userId, prodCat){

    try{
        // rejoiner tracking
        setRejoinerCheckout(cartData, prodCat);

        // search tracking
        trackProductsConverted(cartData)

        // GA4 data layer
        const checkoutData = ga4EventData(cartData, 'begin_checkout');
        sendGTMEvent({ event: 'reset_dl', ecommerce: null});
        sendGTMEvent({ event: 'begin_checkout', ecommerce: { ...checkoutData, user_id: userId } });
        
    }catch(err){ 
        console.log(err)
    }
}


export function cartAnalytics({ cartData, userId }){
    try{
        // GA4 data layer
        const viewCart = ga4EventData(cartData, 'view_cart');
        sendGTMEvent({ event: 'reset_dl', ecommerce: null});
        sendGTMEvent({ event: 'view_cart', ecommerce: { ...viewCart, user_id: userId }});

    }catch(err){
        console.log(err)
    }
}