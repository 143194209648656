import Skeleton from "components/feedback/Skeleton";
import Style from "./PlanPreLoader.module.scss";

function LoadingPlans(){
    return(
        <div className={Style.loadingPlans}>
            <Skeleton height="100px" width="125px" />
            <Skeleton height="100px" width="125px" />
            <Skeleton height="100px" width="125px" />
            <Skeleton height="100px" width="125px" />
        </div>
    )
}

export default LoadingPlans;
