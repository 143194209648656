
function ga4EventData(prodData, event_type) {

    // Send to GA4 ( 10 item-scoped custom dimensions for standard properties)

        switch (event_type) {
        
            // View cart. begin checkout
            case 'view_cart':
            case 'begin_checkout': 
                const cartItems = prodData?.line_items?.physical_items.map((item,i) => {
                    const options = {};
                    let cartItem = {}
                    const disc_amount = item?.discount_amount || (item?.discounts).reduce((n, {discounted_amount}) => n + discounted_amount, 0);
                    
                    if( Array.isArray(item?.options) ){
                        item?.options.length && item?.options.map((opt, i) => {
                            options[`option_selected${i+1}`] = `${opt.name} : ${opt.value}`  //custom dim
                        });
        
                        cartItem = {
                            item_id: item?.product_id,
                            item_name: item?.name,
                            index: i,
                            discount: disc_amount, 
                            coupon: ((prodData?.coupons).find(obj => obj.discounted_amount === disc_amount)?.code) ?? "",
                            item_variant: item?.variant_id,
                            price: item?.sale_price || item?.list_price || item?.original_price,
                            quantity: item?.quantity,
                            //item_category: prodData?.categories,
                            // item_brand: prodData?.brand?.name,
                        }
                    }

                    return { ...cartItem, ...options}
               
                })
                
                const gift_certificates = prodData?.line_items?.gift_certificates.map((item,i) => {  

                    return {
                        item_id: `gift_card_${item.amount}`,
                        item_name: `Gift Card $${item.amount}`,
                        index: i,
                        price: item?.amount,
                        quantity: item?.quantity,
                        item_category: "giftCertificates",
                        item_category2: `${item.name}`,
                        item_variant: item?.id //the id of the item that comes from BC
                    }
                })

                const extendItems = prodData?.line_items?.custom_items.map((item,i) => {  
        
                        return {
                            item_id: item?.sku,
                            item_name: item?.name,
                            index: i,
                            price: item?.extended_list_price || item?.list_price,
                            quantity: item?.quantity,
                            item_variant: item?.id //the id of the item that comes from BC
                        }
                    
                })

                const cartObj = {
                    "currency":  prodData?.currency?.code,
                    "value": prodData?.cart_amount,
                    "items": [ ...cartItems, ...extendItems, ...gift_certificates]                
                }
                return cartObj; 
                break;
            
            case 'view_item':

                let prodItems = {items:[]};

                if (prodData?.product_sets !== null && Object.keys(prodData?.product_sets)) {
                    Object.entries(prodData?.product_sets).forEach(([id, item],i) => {

                        prodItems['items'] = [...prodItems['items'], {
                            item_id: id || item?.sku,
                            item_name: item?.name,
                            index: i,
                            item_variant: item?.variant_id,
                            price: item?.price,
                            quantity: item?.quantity,
                            item_category: (prodData.categories).map(c => c.name).join(', '),
                            item_category2: prodData?.family_code,
                            item_brand: prodData?.brand?.name,
                            item_list_name: prodData?.name,
                            item_list_id: prodData?.sku
                        }]

                    })
                    
                }
                else {
                    const itemCat = {};

                    prodItems['items'] = [...prodItems['items'], {
                                            item_id: prodData?.product_id,
                                            item_name: prodData?.name,
                                            index: 0,
                                            item_variant: prodData?.variant_id,
                                            price: prodData?.price || prodData?.sale_price || prodData?.retail_price,
                                            quantity: 1,
                                            item_category2: prodData?.family_code,
                                            item_category: (prodData.categories).map(c => c.name).join(', '),
                                            item_brand: prodData?.brand?.name                    
                                        }]

                }

                const viewItem = {
                    "currency": "USD",
                    "value": prodData?.sale_price || prodData?.price || prodData?.retail_price,          
                    ...prodItems              
                }

                return viewItem; 
                break;
            
                default:
                    break;

            }           

}


export {
    ga4EventData
}