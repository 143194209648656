

function IconExtendCheck(props){
    return(
        <svg xmlSpace="preserve"viewBox="0 0 46.475 46.474" {...props}>
            <g transform="translate(1359.5 15.94)">
                <path d="M2534.121,513.314a23.185,23.185,0,1,1,9.044-1.827A23.084,23.084,0,0,1,2534.121,513.314Z" transform="translate(-3870.385 -482.78)" fill="#4c7b9a"/>
                <path d="M2541.477,512.519a2.455,2.455,0,0,1-1.735-.718l-5.8-5.8a2.451,2.451,0,1,1,3.466-3.466l4.024,4.021,14.009-14.745a2.45,2.45,0,1,1,3.551,3.376l-15.739,16.567a2.458,2.458,0,0,1-1.745.763Z" transform="translate(-3882.707 -494.486)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default IconExtendCheck;