


export const initialState = {
    selected: {}
}

export const OfferReducer = (state, action) => {
    switch(action.type){
        case "init":
            return {
                ...state,
                ...action.data
            }

        case "setPlan":
            const newState = { ...state.selected };
            delete newState[action?.data?.product_sku];

            return {
                ...state,
                selected: {
                    ...newState,
                    [action.data?.product_sku]: action.data
                }
            }

        default:
            return state;
    }
}

