


/**
 * These can be swatch ordered
 */

export const fabricOptions = [
    "Select Head Cushion Fabric",
    "Select Canopy",
    "Select Optional Cushions",
    "Select Optional Cushion",
    "Select Fabric",
    "Select Cushion",
    "Select Throw Pillows Fabric",
    "Select Sling", 
    "Select Sling Fabric", 
    "Select Base Cushion", 
    "Select Back Cushion"
];