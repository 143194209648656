"use client"

import { useReducer, createContext } from 'react';
import { initialState, OfferReducer } from './OfferReducer';


export const OfferContext = createContext();

// Create our Provider with our Reducer as our state manager
export const OfferContextProvider = props => {
    const [state, dispatch] = useReducer(OfferReducer, initialState);

    return (
        <OfferContext.Provider value={[ state, dispatch ]}>
            {props.children}
        </OfferContext.Provider>
    );
};

