/**
*   Cart Item State Manager:
*/

export const InitialState = {
    price: 0,
    qty: 1,
	options: {},
	autoNoThanks: [],
	sets: {},
	cart: {},
    atcStatus: null,
	setReceiverControl: "",
	addons: {},
	errorAttributes: [],
    customSelectIsOpen: false,
    ref: {},
    readyToAddToCart: false,
    hoverImage: null,
    reviewsRef: null,
    protectionPlans: null
};
 
 
 export const ProductOptionReducer = ((state, action) => {
     switch(action.type){

        case "haveProtectionPlan": 
            return{
                ...state,
                protectionPlans: action.data
            }
            

        case "atc":
            return{
                ...state,
                atcStatus: action.data
            }

        case "setPrice":
            return{
                ...state,
                price: action.data
            }
            

        case "setReviewsRef":
            return{
                ...state,
                reviewsRef: action.data
            }

        case "setQty":
            return{ 
                ...state,
                qty: action.data
            }

        // set swatch or variant image on the main product image
        case "setHoverImage":
            return{
                ...state,
                hoverImage: action.data
            }


        case "readyToAddToCart":
            return {
                ...state,
                readyToAddToCart: action.data
            }


        // notify that our custom select box is open.
        // to allow overflow styling of the main option box cntr
        case "customSelectIsOpen":
            return{
                ...state,
                customSelectIsOpen: action.data
            }


        // set a component reference for addon product offsets
        case "setRef":
            return{
                ...state,
                ref: {
                    ...state.ref,
                    [ action.data.id ]: action.data.offsetTop
                }
            }
 
        // Sets our product's options
        case "setOptions":
            return{
                ...state,
                options:{
                    ...state.options,
                    ...action.data
                }
            };


        // library to set option no thanks automatically
        case "setAutoNoThanks":
            return{
                ...state,
                autoNoThanks:[
                    ...action.data
                ]
            }


        // global set display name that has recently been updated by our global 
        case "setReceiverControl":
            return{
                ...state,
                setReceiverControl: action.data
            }



        // set product set data
        case "setSets": 
            return{
                ...state,
                sets: {
					...state.sets,
					...action.data
                }
            }

        
         // set product set data
        case "setSetProducts": 
            return{
                ...state,
                sets: {
                    ...state.sets,
                    products: {
                        ...action.data
                    }
                }
            }


        // set addons product set data
        case "setAddons": 
            return{
                ...state,
                addons: {
                    ...state.addons,
                    ...action.data
                }
            }


        // set cart product options
        case "setCartProduct":
            return{
                ...state,
                cart:{
                    ...state.cart,
                    ...action.data
                }
            }

            

        case "updateOptions":
            return{
                ...state,
                options:{
                    ...state.options,
                    [action.data?.key]: {
                        ...state.options?.[action.data.key],
                        attributeValue: action.data?.attributeValue,
                        image: action.data?.image ?? null,
                        title: action.data?.title,
                        price: action.data?.price,
                    }
                }
            }
        
            
        case "updateSets":
            return{
                ...state,
                sets: {
                    ...state.sets,
                    [action.data?.product_id]:{
                        ...state.sets?.[action.data?.product_id],
                        [action.data.key]: {
                            ...state.sets?.[action.data.product_id]?.[action.data?.key],
                            ...action?.data
                        }
                    }
                }
            }


        case "updateAddons":
            return{
                ...state,
                addons:{
                    ...state.addons,
                    [action.data?.product_id]: {
                        ...state.addons?.[action.data?.product_id],
                        [action.data.key]: {
                            ...state.addons?.[action.data?.product_id]?.[action.data.key],
                            attributeValue: action.data?.attributeValue,
                            image: action.data?.image ?? null,
                            title: action.data?.title,
                            price: action.data?.price
                        }
                    }
                }
            }



        case "updateCart":
            return{
                ...state,
                cart:{
                    ...state.cart,
                    [action.data?.product_id]: {
                        ...state.cart?.[action.data?.product_id],
                        [action.data.key]: {
                            ...state.cart?.[action.data?.product_id]?.[action.data.key],
                            attributeValue: action.data?.attributeValue,
                        }
                    }
                }
            }
        

        case "updateSetProductQuantity":
            return{
                ...state,
                sets:{
                    ...state.sets,
                    products: {
                        ...state.sets?.products,
                        [action.data?.product_id]: {
                            ...state.sets?.products?.[action.data?.product_id],
                            quantity: action.data?.qty
                        }
                    }
                }
            }

        

        case "setErrorAttributes":
            return{
                ...state,
                errorAttributes: action.data
            }
        
 
        default: return state;
     }
 })