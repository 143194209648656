

function IconExtend(props){
    const { fill } = props;

    return(
        <svg xmlSpace="preserve" viewBox="0 0 206 163" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M110.789 32.7359L136.173 -1.14441e-05H197.888L141.76 69.1092L110.789 32.7359Z" fill={fill ?? "#00C9FF"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M205.345 162.611H141.568C141.568 162.611 103.247 117.029 101.188 114.292C91.6868 126.959 62.8264 162.611 62.8264 162.611H0L69.8339 78.0533L3.20644 0.0626907H66.6268C66.6268 0.0626907 112.866 54.2455 205.345 162.611Z" fill={fill ?? "black"}/>
        </svg>
    )
}

export default IconExtend;