"use client"

import { useReducer, createContext } from 'react';
import { ProductOptionReducer, InitialState } from './ProductOptionReducer';

export const ProductOptionContext = createContext();

// Create our Provider with our Reducer as our state manager
export const ProductOptionContextProvider = props => {
    const [state, dispatch] = useReducer(ProductOptionReducer, InitialState);
  
    return (
        <ProductOptionContext.Provider value={[state, dispatch]}>
            {props.children}
        </ProductOptionContext.Provider>
    );
}
