/**
 * ATC a custom Single Product
 * https://developer.bigcommerce.com/docs/rest-management/carts/items#add-cart-line-items
 * 
 * @param {object} itemMutation - mutation hook 
 * @param {object} productData - product data
 * @param {function} errorCallback - error callback
 * @param {function} confirmationCallback - confirmation callback
 * @param {string} cartId - cart id (if we need to add it to a specific cart)
 * @param {boolean} buyNow - buy now
 */

export default function atcCustomProduct({ 
    cartId = null,
    productData, 
    buyNow = false,
    itemMutation, 
    errorCallback = () => null, 
    confirmationCallback = () => null, 
}){

    const { sku, name, quantity, list_price } = productData ?? {};

    const productBody = {
        custom_items: [{
            sku,
            name,
            quantity,
            list_price,
        }]
    }
    
    itemMutation.mutate({ type: "add", productBody, cartId, buyNow }, 
        {
            onSuccess: (data) => { 
                if( 
                    data?.response?.status === 404 ||
                    data?.response?.status === 308
                ){
                    errorCallback(data.response, productData) 
                    return null;
                }      

                confirmationCallback(data);
            },

            onError: async (err) => {
                errorCallback(err, productData)
            }
        }
    )
}