"use client"

import CloseBtn from "components/forms/CloseButton";
import Style from "./OfferModal.module.scss";
import React, { useEffect } from "react";
import clsx from "clsx";


const OfferModal = React.forwardRef(({ 
    children, 
    canClose = true , 
    className
}, ref ) => {

    useEffect(() => {
        function preventClose(e){
            if( e.key === "Escape" && !canClose ){
                e.preventDefault()
            }
        }

        if(ref?.current) ref.current.addEventListener("keydown", (e) => preventClose(e))

        return () => {
            if(ref?.current) ref?.current.removeEventListener("keydown", (e) => preventClose(e))
        }

    }, [ ref ])


    return(
        <dialog className={clsx(Style.popover, className)} ref={ref}>
            {canClose && (
                <CloseBtn 
                    className={Style.closeBtn}
                    shouldClose={() => ref.current.close()}
                />
            )}

            {children}
        </dialog>
    )
})

OfferModal.displayName = 'OfferModal';

export default OfferModal;