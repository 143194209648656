import GetLoggedJwtToken from 'services/bigcommerce/GetLoggedJwtToken';
import axios from 'axios';


export async function getCustomerCheckoutUrl(cartData){
   return axios.post(`/api/bigcommerce/cart/redirect_urls/${cartData?.id}`)
        .then( async (response) => {
            const newCheckoutUrl = response?.data?.data?.checkout_url;

            await GetLoggedJwtToken(cartData?.customer_id, newCheckoutUrl)
                .then(response => {
                    const jwtToken = response?.data?.token;
                    const loggedInCheckoutUrl = process.env.NEXT_PUBLIC_checkoutUrl + `/login/token/${jwtToken}`;

                    window.location.href = loggedInCheckoutUrl;
                })
                .catch(err => {
                    console.log(err)
                });  
                
        })
        .catch(err => {
            console.log(err)
        })
}